<template>
  <div class="container">
    <HorizontalHeader text="UX Design" />
    <div class="outer-ux-item" v-for="(value, _, index) in projects" :key="index">
      <ProjectOverview
          v-if="(index + 1) < 4"
        :header="value.name"
        :index="index"
        label="explore"
        :body="value.overview"
        screen="mobile"
        :destination="value.path"
      />
    </div>
  </div>
</template>

<script >
import { store } from "@/store/store.js";
import { colors } from "@/core/colors.js";
import { data } from "@/core/data";
import HorizontalHeader from "@/components/info/HorizontalHeader.vue";
import ProjectOverview from "@/components/info/ProjectOverview.vue";
import { ScreenType } from "@/components/info/types.ts";

export default {
  store: store,
  components: {
    HorizontalHeader,
    ProjectOverview,
  },
  data() {
    return {
      formFactor: ScreenType.Mobile,
    };
  },
  computed: {
    projects: function () {
      let result;
      result = data.written.projects;
      return result;
    },
  },
};
</script>

<style scoped>
button {
  width: calc(100% - 4rem);
}
.container {
  margin: 5.25rem 0 0 0;
  display: grid;
  padding: 2rem;
  grid-template-rows: 1.875rem auto;
  justify-content: start;
}
.outer-ux-item {
  display: grid;
  margin: 5.625rem 0 0 0;
}
</style>