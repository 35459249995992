<template>
  <div class="thumb-container">
    <img
      v-if="index == 1"
      alt="Illustration of a web interface with the side panel popping off the page, a check mark to the right of that and a line graph"
      src="@/assets/images/design_system/analytics_ui/lumi_ds_thumb.webp"
    />
    <img
      v-else-if="index == 2"
      alt="A pile of pages with a preview of the design system on the front page"
      src="@/assets/images/SD_DS_thumb.webp"
    />
    <img
      v-else-if="index == 3"
      alt="A desktop and a mobile screenshot of the Sentinel Digital home page."
      src="@/assets/images/SD_thumb.webp"
    />
    <img
      v-else-if="index == 0"
      alt="A couple of hanging tags"
      src="@/assets/images/tag_editor/tags_thumb.webp"
    />
  </div>
  <!-- thumb container-->
</template>

<script>
import Vue from "vue";
import { store } from "@/store/store";
import { colors } from "@/core/colors.js";

export default Vue.extend({
  store: store,
  props: {
    index: {
      type: Number,
    },
    screen: {
      type: String,
      default: "desktop",
    },
    thumbnail: {
      type: String,
    },
  },
  computed: {
    myColors() {
      return colors(this.$store.getters.isDark, this.$store);
    },
    imageSize() {
      return this.screen == "mobile"
        ? {
            height: "12.687rem",
          }
        : {
            blockSize: "13rem",
            maxInlineSize: "10rem",
          };
    },
    imagePosition() {
      return window.innerWidth < 700
        ? {
            left: "5rem",
            "margin-top": "5rem",
          }
        : {
            left: "23rem",
            "margin-top": "5rem",
          };
    },
    isDesktop() {
      return window.innerWidth > 700 ? true : false;
    },
    mosaicPosition() {
      return window.innerWidth < 700
        ? { margin: "-3rem 0 0 0" }
        : { margin: "0" };
    },
  },
  mounted: function () {
    console.log(window.innerWidth);
  },
});
</script>

<style scoped>
.t1 {
  display: grid;
}

img {
  object-fit: contain;
  position: relative;
  block-size: 100%;
  inline-size: 100%;
}

@media (max-width: 700px) {
  img {
    /*margin-block-start: 5rem;*/
  }
}

.thumb-container {
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  place-content: center;
  block-size: fit-content;
  inline-size: 100%;
}

@media screen and (max-width: 830px) {
  .thumb-container {
    block-size: 100%;
    max-block-size: 20rem;
  }
}

.mosaic {
  display: grid;
  grid-template-rows: 14rem auto;
}

.mosaic > div {
  width: 100%;
  display: grid;
  grid-template-columns: 2rem auto;
}

.right {
  display: grid;
  grid-template-rows: 3rem auto;
}

.right > div:nth-child(2) {
  height: 8rem;
}

.left {
  display: flex;
  justify-content: flex-end;
  flex-flow: column;
}

.left > div:nth-child(1) {
  width: 1rem;
  height: 3rem;
  position: relative;
  bottom: 4rem;
  left: 0.3rem;
}

.left > div:nth-child(2) {
  width: 100%;
  height: 2rem;
}
</style>
