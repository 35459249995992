<template>
  <main class="outer-container">
    <div class="inner-container">
      <NewSectionHeader headerText="ux design" v-bind:isHomepage="false" />
      <!--      <div>-->
      <div class="rows">
        <div v-for="(value, _, index) in projects" :key="index" class="row">
          <ProjectOverview
            :body="value.overview"
            :destination="value.path"
            :header="value.name"
            :index="index"
            :thumbnail="value.thumbnail"
            label="explore"
            screen="desktop"
          />
        </div>
      </div>
    </div>
    <!--        </div>-->
    <BackToTop />
  </main>
</template>

<script lang="js">
import SectionHeader from "@/components/section_header/SectionHeader.vue";
import {store} from "@/store/store.js";
import {colors} from "@/core/colors.js";
import {data} from "@/core/data";
import ProjectOverview from "@/components/info/ProjectOverview.vue";
import UpArrow from "@/components/svg/UpArrow.vue";
import BackToTop from "@/components/controls/button/BackToTop.vue";
import {scrollMixin} from "@/core/mixins/scroll_mixin";
import NewSectionHeader from "@/components/section_header/NewSectionHeader.vue";

export default {
  mixins: [scrollMixin],
  store: store,
  components: {
    NewSectionHeader,
    SectionHeader,
    ProjectOverview,
    BackToTop,
  },

  data() {
    return {
      label: "explore",
    };
  },
  computed: {
    isDark() {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.$store.getters.isDark, this.$store);
    },
    projects: function () {
      let result;
      result = data.written.uxProjects;
      return result;
    },
    projectCount: function () {
      return Object.keys(data.written.uxProjects).length;
    },
  },
  methods: {
    renderHover: function () {
      this.hasHovered = true;
    },
    renderNormal: function () {
      this.hasHovered = false;
    },
  },
};
</script>

<style scoped>
body {
  block-size: 120vh;
}

.row {
  display: grid;
  block-size: 100%;
  place-content: center;
}

.rows {
  display: grid;
  grid-column: 2 / span 1;
  block-size: fit-content;
  gap: 6rem;
  padding-block-start: 9rem;
}

@media screen and (max-width: 830px) {
  .rows {
    grid-column: 1;
    block-size: 100%;
    padding: 0;
    gap: 1rem;
  }
}

.outer-container {
  margin-block-start: 3.2rem;
  inline-size: 100%;
  display: grid;
  block-size: fit-content;
  box-sizing: border-box;
  grid-template-columns: 1fr;
  place-items: center;
  padding-inline-end: 2rem;
}

@media screen and (max-width: 830px) {
  .outer-container {
    padding-inline: 2rem;
  }
}

.inner-container {
  display: grid;
  grid-template-columns: 12rem 1fr;
  block-size: fit-content;
}

@media screen and (max-width: 830px) {
  .inner-container {
    grid-template-columns: 1fr;
    grid-template-rows: 4rem 1fr;
    block-size: fit-content;
  }
}
</style>
