<template>
  <a href="#/ux"
  ><h1
      :class="shouldRotate ? 'rotate-text' : 'horizontal-text'"
      :style="[
        {
          color: myColors.main,
        },
      ]"
  >
    {{ headerName }}
  </h1></a
  >
</template>

<script>
import {store} from "@/store/store.js";
import {colors} from "@/core/colors.js";
import {textStyles} from "@/core/styles/text_styles.ts";

export default {
  name: "NewHeader",
  store: store,
  props: {
    sectionName: String,
    shouldRotate: {
      type: Boolean,
      default: true,
    },
    topPosition: {
      type: String,
      default: "",
    },
    headerType: {
      type: String,
      default: "large",
    },
    isHomepage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      headerName: this.sectionName,
    };
  },
  computed: {
    myColors: function () {
      return colors(this.$store.getters.isDark, this.$store);
    },
    position: function () {
      return this.isHomepage ? {left: ""} : {left: "10rem"};
    },
    headerStyle: function () {
      if (this.headerType == "large") {
        return {
          "font-size": "2rem",
          "font-weight": "600",
        };
      } else if (this.headerType == "small") {
        return {
          "font-size": "2rem",
          "font-weight": "600",
        };
      } else if (this.headerType == "xSmall") {
        return textStyles.headers.desktopHeader.verticalSmallHeader;
      }
      return null;
    },
  },
  myColors: function () {
    return colors(this.$store.getters.isDark, this.$store);
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

h1 {
  text-decoration: none;
  position: relative;
  font-weight: 500;
  text-transform: uppercase;
  font-size: x-large;
  font-weight: bold;
}

@media screen and (max-width: 830px) {
  h1 {
    font-size: large;
  }
}

.rotate-text {
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
}

@media screen and (max-width: 830px) {
  .rotate-text {
    writing-mode: lr;
    transform: rotate(0);
  }
}

.horizontal-text {
  text-orientation: initial;
  writing-mode: lr;
  transform: rotate(0deg);
}

h1:hover {
  text-decoration: underline;
}
</style>
