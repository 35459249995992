<template>
  <div :class="isLarge ? 'container' : 's-container'">
    <div :class="isLarge ? 'title' : 's-title'">
      <NewHeader
        :headerType="headerSize"
        :isHomepage="isHomepage"
        :sectionName="headerText"
        :shouldRotate="isLarge == true"
      />
    </div>
    <div :class="isLarge ? 'graphic' : 's-graphic'">
      <div :style="[{ background: myColors.foregroundAccentActive }]"></div>
      <div :style="[{ background: myColors.foregroundAccentActive }]"></div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Header from "@/components/appBody/section/desktop/Header.vue";
import { data } from "@/core/data/index";
import { colors } from "@/core/colors";
import { store } from "@/store/store.js";
import { boxes, textPostion } from "@/components/section_header/box_styles.ts";
import NewHeader from "@/components/appBody/section/desktop/NewHeader.vue";

export default {
  props: {
    headerSize: {
      type: String,
      default: "small",
    },
    headerText: {
      type: String,
    },
    isHomepage: {
      type: Boolean,
      default: true,
    },
    isLarge: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    NewHeader,
    Header,
  },
  data() {
    return {
      mosaicBlockColors: {
        "background-color": colors(this.$store.getters.isDark, this.$store)
          .foregroundAccent,
      },
    };
  },
  store: store,
  computed: {
    myColors: function () {
      return colors(this.$store.getters.isDark, this.$store);
    },
    uxHeader: function () {
      return data.written.skills.ux.name;
    },
    topBoxStyle: function () {
      if (this.headerSize == "small") {
        return boxes.topBox.mode.small;
      } else if (this.headerSize == "xSmall") {
        return boxes.topBox.mode.xSmall;
      }
      return {};
    },
    bottomBoxStyle: function () {
      if (this.headerSize == "small") {
        return boxes.bottomBox.mode.small;
      } else if (this.headerSize == "xSmall") {
        return boxes.bottomBox.mode.xSmall;
      }
      return {};
    },
    headerPosition: function () {
      if (this.textPostion == "small") {
        return textPostion.small;
      } else if (this.headerSize == "xSmall") {
        return textPostion.xSmall;
      }
      return {};
    },
  },
};
</script>

<style scoped>
img {
}

.container {
  inline-size: fit-content;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  place-content: center center;
  place-items: center;
  block-size: 9rem;
  position: sticky;
  top: 5rem;
}

@media screen and (max-width: 830px) {
  .container {
    block-size: 100%;
    top: 0;
    inline-size: 6rem;
  }
}

.s-container {
  inline-size: fit-content;
  display: grid;
  place-content: center center;
  grid-template-rows: 1fr 4fr 1fr;
  grid-template-columns: 1fr;
  block-size: 2rem;
}

.graphic {
  block-size: 9rem;
  inline-size: 9rem;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-rows: 1fr 4fr 1fr;
  grid-column: 3 / span 1;
  grid-row: 3 / span 1;
}

@media screen and (max-width: 830px) {
  .graphic {
    inline-size: 4rem;
    block-size: 4rem;
  }
}

.s-graphic {
  block-size: 2rem;
  inline-size: 2rem;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-rows: 1fr 4fr 1fr;
  grid-column: 1 / span 1;
  grid-row: 1 / span 3;
}

.title {
  display: grid;
  inline-size: fit-content;
  grid-row: 3 / span 1;
  grid-column: 3 / span 1;
  z-index: 10;
}

@media screen and (max-width: 830px) {
  .title {
    inline-size: 7rem;
  }
}

.s-title {
  inline-size: fit-content;
  z-index: 10;
  grid-row: 2 / span 1;
  grid-column: 2 / span 2;
  position: relative;
  top: 5%;
}

.graphic > div:nth-child(1),
.s-graphic > div:nth-child(1) {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
}

.graphic > div:nth-child(2),
.s-graphic > div:nth-child(2) {
  grid-row: 3 / span 1;
  grid-column: 1 / span 1;
}
</style>
