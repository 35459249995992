<template>
  <div>
    <h1 :style="[headerStyle, textColor, textDecoration]">{{text}}</h1>
  </div>
</template>

<script>
import { store } from "@/store/store.js";
import { colors } from "@/core/colors.js";
import Vue from "vue";
import { textStyles } from "@/core/styles/text_styles.ts";

export default Vue.extend({
  data() {
    return {
      headerStyle:
        textStyles?.headers?.mobileHeader?.horizontalHeader.formatted,
    };
  },
  computed: {
    textColor() {
      return { color: colors(this.$store.getters.isDark, this.$store).main };
    },
    textDecoration() {
      return {
        "border-bottom": `2px solid ${
          colors(this.$store.getters.isDark, this.$store).main
        }`,
      };
    },
  },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
});
</script>

<style scoped>
div {
  margin: 0 0 4rem 0;
}
</style>