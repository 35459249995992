import { render, staticRenderFns } from "./UXHome.vue?vue&type=template&id=2a952cce&scoped=true&"
import script from "./UXHome.vue?vue&type=script&lang=js&"
export * from "./UXHome.vue?vue&type=script&lang=js&"
import style0 from "./UXHome.vue?vue&type=style&index=0&id=2a952cce&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_sqwtrzbl6s4ypz4dmnoirtpj7i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a952cce",
  null
  
)

export default component.exports