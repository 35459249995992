<template>
  <div class="ux-item">
    <ThumbContainer :index="index" :thumbnail="thumbnail" screen="mobile" />
    <div class="text-container">
      <h1 :style="{ color: myColors.main }">{{ header }}</h1>
      <div>
        <p :style="{ color: myColors.onBackground }">{{ body }}</p>
        <div class="cta">
          <CTA :buttonWidth="buttonWidth" :label="label" :path="destination" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "@/store/store.js";
import { colors } from "@/core/colors.js";
import ThumbContainer from "@/components/info/ThumbContainer.vue";
import CTA from "@/components/controls/button/CTA.vue";

export default {
  store: store,
  components: {
    ThumbContainer,
    CTA,
  },
  props: {
    header: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    screen: {
      type: String,
      default: "desktop",
    },
    destination: {
      type: String,
    },
    thumbnail: {
      type: String,
    },
  },
  computed: {
    myColors: function () {
      return colors(this.$store.getters.isDark, this.$store);
    },
    span: function () {
      return this.screen == "mobile"
        ? {
            width: "",
          }
        : {
            width: "30.5rem",
          };
    },
    layout: function () {
      return this.screen == "mobile"
        ? {
            "grid-template-rows": "17rem auto",
            height: "",
          }
        : {
            "grid-template-columns": "24rem 1fr",
            height: "18rem",
          };
    },
    buttonWidth: function () {
      if (this.screen == "desktop") {
        return "50%";
      } else {
        return "100%";
      }
    },
  },
};
</script>

<style scoped>
.ux-item {
  inline-size: 100%;
  display: grid;
  gap: 1rem;
  max-inline-size: 60rem;
  grid-template-columns: 40% 1fr;
  place-content: flex-start;
}

@media (max-width: 830px) {
  .ux-item {
    grid-template-rows: 36% 1fr;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

.cta {
  inline-size: 100%;
  max-inline-size: 20rem;
  margin-block-start: 1rem;
}

.ux-item > div:nth-child(2) {
  height: 100%;
}

.t1 {
  height: 1.25rem;
  width: 2rem;
  position: relative;
  top: 1.2rem;
  left: 1rem;
}

.text-container {
  display: flex;
  flex-direction: column;
  padding: 0;
  inline-size: 100%;
  max-inline-size: 60rem;
}

@media (max-width: 830px) {
  .text-container {
    max-inline-size: 30rem;
    gap: 1rem;
  }
}

.text-container > div > p {
  font-size: 1rem;
  line-height: 1.75rem;
  text-align: left;
  inline-size: 100%;
}

.text-container > div {
  display: flex;
  flex-direction: column;
  inline-size: 100%;
}

h1 {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.6rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  order: 0;
  align-self: flex-start;
  text-align: left;
}

@media screen and (max-width: 875px) {
  h1 {
    font-size: large;
    line-height: 1.3;
    letter-spacing: 0.1rem;
  }
}
</style>
